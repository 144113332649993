<template>
  <div id="admin-dashboard">
    <header>
      <div>
        <i class="fas fa-home"></i>
        <label v-if="admin != null">Panel administracyjny ({{ admin.email }})</label>
      </div>

      <i class="fas fa-power-off" @click="logout"></i>
    </header>

    <main>
      <div class="box add-client">
        <h2>Dodaj klienta</h2>
        <form @submit.prevent="addClient">
          <input type="text" placeholder="Nazwa klienta" v-model="clientName" required>
          <input type="email" placeholder="E-mail" v-model="clientEmail" required>
          <input type="password" placeholder="Hasło" minlength="6" v-model="clientPassword" required>
          <button type="submit">Dodaj klienta</button>
        </form>
      </div>

      <div class="box">
        <h2>Wybierz klienta:</h2>
        <select v-model="selectedClient">
          <option v-for="(client, index) in clients" :key="index" :value="client">{{ client.name }} ({{ client.email }})</option>
        </select>

        <div class="client-box" v-if="selectedClient">
          <div class="client-box-header">
            <span>{{ selectedClient.name }} ({{ selectedClient.email }})</span>
            <button v-if="admin.uid != selectedClient.id" class="delete-btn" @click="deleteClient(selectedClient)">Usuń klienta</button>
          </div>
          

          <div class="client-box-content">
            <h3>Gry</h3>
            <div v-for="(game, index) in games" :key="index">
              <input type="checkbox" :checked="checkOwnedGame(game.url)" @change="assignGame($event, game)"> {{ game.name }}
            </div>
          </div>
        </div>
      </div>
    </main>
    
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
  data() {
    return {
      adminsIds: [],
      admin: null,
      games: {
        game1a: {
          name: 'Tajemnice Karkonoskiego Lasu - zwierzęta (trasa łatwa)',
          url: '/game-1a',
          active: false,
          pass: "Dbając o przyrodę dbamy o zwierząt swobodę"
        },
        game1b: {
          name: 'Tajemnice Karkonoskiego Lasu - zwierzęta (trasa trudna)',
          url: '/game-1b',
          active: false,
          pass: "Dbając o przyrodę dbamy o zwierząt swobodę"
        },
        game1c: {
          name: 'Tajemnice Karkonoskiego Lasu - zwierzęta (QUIZ)',
          url: '/game-1c',
          active: false,
          pass: "Dbając o przyrodę dbamy o zwierząt swobodę"
        },
        game2: {
          name: 'Odkryj Karpacz - dawni mieszkańcy',
          url: '/game-2',
          active: false,
          pass: "Przestajemy istnieć kiedy popadamy w zapomnienie"
        },
        game3: {
          name: 'Gra 3',
          url: '/game-3',
          active: false,
          pass: ""
        },
        game4: {
          name: 'W Karpaczu MISIE podoba',
          url: '/game-4',
          active: false,
        }
      },
      clients: [],
      clientName: "",
      clientEmail: "",
      clientPassword: "",
      selectedClient: null,
    }
  },
  mounted() {
    const db = firebase.firestore();
    db.collection("admins").doc("adminsids").get().then((doc) => {
      this.adminsIds = doc.data().ids;

      firebase.auth().onAuthStateChanged(user =>  {
        let foundAdmin = -1;
        if (user)
          foundAdmin = this.adminsIds.findIndex(id => id == user.uid);
        
        if (foundAdmin >= 0) {
          this.admin = user;
          this.$store.commit('SET_USER', user);
          this.fetchClients();
        } else {
          this.$router.push('/admin');
        }
      });
    });
  },
  methods: {
    logout() {
      firebase.auth().signOut().then(() => {
        this.$router.push('/admin');
        this.$store.commit('LOGOUT_USER');
      });
    },
    fetchClients() {
      const db = firebase.firestore();

      db.collection('clients').onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            console.log('Added: ', change.doc.data());
            let newClient = Object.assign({id: change.doc.id}, change.doc.data());
            this.clients.push(newClient);
          }
          if (change.type === 'modified') {
            console.log('Modified: ', change.doc.data());
            this.clients.splice(this.clients.findIndex(i => i.id === change.doc.id), 1);
            let newClient = Object.assign({id: change.doc.id}, change.doc.data());
            this.clients.push(newClient);
          }
          if (change.type === 'removed') {
            console.log('Removed: ', change.doc.data());
            this.clients.splice(this.clients.findIndex(i => i.id === change.doc.id), 1);
          }
        });
      });
    },
    async addClient() {
      const db = firebase.firestore();
      await db.collection('clients').doc().set({
        name: this.clientName,
        email: this.clientEmail,
        password: this.clientPassword,
      }, { merge: true });

      alert("Dodano nowego klienta.");
      this.clientName = "";
      this.clientEmail = "";
      this.clientPassword = "";
    },
    async deleteClient(client) {
      const db = firebase.firestore();
      await db.collection('clients').doc(client.id).delete();
      // Usuwanie użytkowników których clientId == client.id
      db.collection('users').where('clientId', '==', client.id).get().then(querySnap => {
        querySnap.forEach(doc => {
          doc.ref.delete();
        });
      });
    },
    checkOwnedGame(gameUrl) {
      let gameName = gameUrl.replace(/[^\w\s]/gi, '')
      if ('games' in this.selectedClient) {
        if (gameName in this.selectedClient.games) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async assignGame(e, game) {
      let gameName = game.url.replace(/[^\w\s]/gi, '')

      if (e.target.checked) {
        console.log("Przydziel grę", gameName);
        
        if ('games' in this.selectedClient) {
          this.selectedClient.games[gameName] = game;
        } else {
          this.selectedClient['games'] = {};
          this.selectedClient.games[gameName] = game;
        }
      } else {
        console.log("Usuń grę", gameName);
        delete this.selectedClient.games[gameName];
        //this.selectedClient.games = this.selectedClient.games.filter();
      }

      // Aktualizuj bazę
      const db = firebase.firestore();
      await db.collection('clients').doc(this.selectedClient.id).update({
        'games': this.selectedClient.games,
      });
    },
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap');

header, main, input {
  font-family: 'Source Sans Pro', sans-serif;
}
header {
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25); */
  font-size: 1.5rem;
  z-index: 10;
}
header > div {
  display: flex;
  align-items: center;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: .5rem;
  font-weight: bold;
}
main {
  margin-top: 50px;
  padding: 1rem;
  background: #fff;
  min-height: calc(100vh - 50px);
  display: flex;
}
.box {
  min-height: 100px;
  width: 100%;
  background: linear-gradient(#f8f8f8, #fff);
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
h2 {
  margin: 0 0 1rem 0;
  text-transform: uppercase;
}
h3 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
form > * {
  margin: .5rem 0;
}
input, select {
  padding: .5rem;
  border-radius: .25rem .25rem 0 0;
  border: none;
  border-bottom: 2px solid #0080bb;
}
.add-client {
  flex-basis: 40%;
}
.add-client form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
button {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}
button:hover {
  animation: unset;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.client-box {
  width: 100%;
  margin: 1rem 0;
  box-shadow:0 0 10px 0 rgba(0,0,0,.1);
}
.client-box-header {
  background: linear-gradient(45deg, #00c1cf, #0080bb);
  color: #fff;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: .25rem .25rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-box-content {
  text-align: left;
  padding: .5rem;
}
.delete-btn {
  background: tomato;
  margin: 0;
}
@media screen and (max-width: 768px) {
  main {
    flex-direction: column;
  }
}
</style>